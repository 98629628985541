<template>
  <v-card outlined class="pa-3" elevation="2" :loading="loading">
    <v-card-title primary-title class="py-0">
      <slot name="number"></slot>
    </v-card-title>
    <v-divider class="mx-3"></v-divider>
    <v-card-text class="py-2">
      <v-row dense>
        <!-- Info producto -->
        <v-col cols="12">
          <slot name="description"></slot>
        </v-col>
        <!-- Info documento -->
        <v-col cols="7">
          <slot name="doc-description"></slot>
        </v-col>
        <v-col cols="5">
          <slot name="doc-type"></slot>
        </v-col>

        <!-- Info usuario -->
        <v-col cols="7">
          <slot name="name"></slot>
        </v-col>
        <v-col cols="5">
          <slot name="doc-number"></slot>
        </v-col>
      </v-row>

      <v-divider style="background: #d9d9d9" class="my-4"></v-divider>
      <div>
        <slot name="detail"></slot>
      </div>
    </v-card-text>
    <v-card-actions class="py-0">
      <slot name="button"></slot>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "SideBarCards",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
